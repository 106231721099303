.footer {
  border-radius: 35px 35px 0px 0px;
  background: var(--black, #343434);
  padding-bottom: 40px;
  padding: 10px 10px 40px;

  display: flex;
  justify-content: center;

  .link {
    color: var(--white);
    text-decoration: underline;
    padding: 20px 0;

    &:hover {
      text-decoration: none;
    }
  }
}
.top_line {
  border-bottom: 1px solid white;
}
.top_line,
.bottom_line {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.left_column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0;
}
.right_column {
  align-self: flex-end;
  display: flex;
  gap: 10px;

  button {
    padding: 0;
  }
}

.socialmedia {
  margin-bottom: 18px;
}
/* .centered_container{
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1410px;
  position: relative;
  width: 100%;
} */

.svg {
  &:hover {
    path {
      fill: rgb(123, 242, 255);
    }
  }
}

.socialMediaLink {
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 15px rgb(0, 0, 0);
  transition: 0.2s background-color ease-in;

  svg {
    path {
      fill: rgb(0, 0, 0);
    }
  }

  &:hover {
    background-color: transparent;
    box-shadow: unset;

    svg {
      path {
        fill: var(--violet);
      }
    }
  }

  // &.instagramBg {
  //   background: linear-gradient(
  //     45deg,
  //     #f09433 0%,
  //     #e6683c 25%,
  //     #dc2743 50%,
  //     #cc2366 75%,
  //     #bc1888 100%
  //   );
  //   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  // }
  // &.facebookBg {
  //   background: -webkit-linear-gradient(top, #3b5998, #2b4170);
  // }
  // &.youtubeBg {
  //   background-color: rgb(255, 0, 0);
}
