.modalWrapper {
  form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    button {
      align-self: flex-start;
    }
  }
}
