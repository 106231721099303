.header {
  display: flex;
  flex: 1;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  background-color: white;
  width: 100%;
  box-shadow: -1px 28px 55px -3px rgba(34, 60, 80, 0.25);
  // padding-left: 20px;
  // padding-right: 20px;

  .flexContainer {
    display: grid;
    grid-template-columns: 70px 1fr minmax(70px, auto);
    align-content: center;
    gap: 100px;
    width: 100%;

    @media (max-width: 960px) {
      & {
        display: flex;
        justify-content: space-between;
      }
    }

    .headerLeftBlock {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .headerCenterBlock {
      display: flex;
      align-items: center;
      position: relative;
      justify-self: center;
      gap: 40px;

      @media (max-width: 960px) {
        & {
          display: none;
        }
      }

      a {
        color: var(--black, #343434);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
      }
      a.contact_us_link {
        text-decoration: underline;
        font-size: 18px;
      }
      a:hover {
        color: var(--gray, #8b8b8b);
      }
    }

    .headerRightBlock {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .burgerAction {
      align-self: center;
    }
  }
}

// .authBox {
//   display: flex;
//   flex-direction: column;
//   gap: 10px;
//   justify-content: flex-end;
//   position: relative;
// }

.bgItem {
  display: flex;
  // align-self: flex-end;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 15px;
  background: linear-gradient(180deg, #ff0060 0%, #921342 100%, #921342 100%);
  // padding: 18px 19px;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  cursor: pointer;
  white-space: nowrap;
  z-index: 10;
  justify-self: flex-end;
  transition: 0.1s box-shadow ease-in;
}
