@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url(./nule.scss);

:root {
  --pink: #fe0160;
  --white: #fff;
  --black: #343434;
  --grey: #8b8b8b;
  --violet: #5decff;
  --tiffany: #00c0da;
  --text-color: #010000;
}

body {
  margin: 0;
  font-family: -apple-system, Inter, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.swiper {
  position: relative;

  .blur {
    width: 1px;
    height: 100%;
    background-color: #f5f5f5;
    position: absolute;
    top: 0;
    z-index: 2;
  }

  .leftBlur {
    left: -30px;
    box-shadow: 30px 0px 20px 20px #f5f5f5, 52px 0px 100px 100px #f5f5f5,
      0px 0px 5px 10px #f5f5f5;
  }
  .rightBlur {
    right: -30px;
    box-shadow: -30px 0px 20px 20px #f5f5f5, -52px 0px 100px 100px #f5f5f5,
      0px 0px 5px 10px #f5f5f5;
  }

  .swiper-slide {
    transition: 0.3s transform ease-in;
  }
  .swiper-slide:not(.swiper-slide-active) {
    transform: scale(0.9);
  }

  // .swiper-pagination-bullet {
  //   background-color: var(--violet);
  // }
  .swiper-pagination-bullet-active {
    background-color: var(--violet);
  }
  .swiper-button-prev,
  .swiper-button-next {
    color: var(--violet);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // background-image: url("/assets/images/background.svg");
  // background-repeat: no-repeat;
  // background-size: cover;
}

.mainBlock {
  flex: 1 1 auto;
}

a {
  color: unset;
}
.centered_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section {
  max-width: 1400px;
  width: 100%;
}

h3 {
  color: var(--text-color);
  /* 1600/H3-Inter(b)-18px */

  // font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 25.2px */
  // text-transform: uppercase;
}

.font25 {
  color: var(--white, #fff);

  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 35px */
  text-decoration-line: underline;
  // text-transform: uppercase;
}

.font16 {
  color: var(--White, var(--White, #fff));
  /* 1600/Menu-Inter(r)-16px */

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
  // text-transform: uppercase;
}
.font13 {
  color: var(--White, var(--White, #fff));
  /* 1600/T3-Inter(r)-13px */

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
}

.skeleton {
  height: 310px;
  width: 200px;
  background-color: black;
}

.logo {
  height: 62px;
  width: 70px;
  g {
    fill: #343434;
  }

  @media (max-width: 600px) {
    & {
      width: 50px;
      height: 50px;
    }
  }
}
