.toast {
  padding: 12px;
  color: black;
  border-radius: 10px;
  background-color: rgb(249, 249, 249);
  border: 1px solid rgb(163, 159, 148);
  display: inline-flex;
  column-gap: 15px;
  align-items: center;
  width: auto;
  line-height: 130%;
  max-width: 350px;
  transition: opacity 0.2s;
  .iconBlock {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
    background-color: rgb(244, 205, 31);
    svg {
      width: 12px;
      align-self: center;
    }
  }
  p {
    margin: 0;
  }
}
